:root {
  // color-scheme: light dark;
  --nav-bg-color: #F7F7F7;
  --content-bg-color: #FFFFFF;
  --font-color: rgba(0,0,0,.85);
  --font-desc-color: rgba(0,0,0,.4);
  --border-color: #f0f0f0;
  
}

@media (prefers-color-scheme: dark) {
  :root {
      --nav-bg-color:#131112;
      --content-bg-color: #1a1819;
      --font-color: rgba(255, 255, 255, .85);
      --font-desc-color:rgba(255, 255, 255, .4);
      --border-color: #504c4c;
  }
}

html {
  font-size: calc(100vw/25);
}
body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;
  background-color: var(--nav-bg-color) !important;
  color: var(--font-color) !important;
}
