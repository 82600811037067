$padding-height: 0.4rem;
$dots-width: 0.6rem;
$button-padding: 0.6rem;

.container {
  :global {
    .ant-spin-container::after {
      background: var(--content-bg-color);
    }
  }
  padding: $padding-height 0;
  .desc {
    // margin-top: 1vh;
    height: 8rem;
    // background-color: #FFF;
    background-color: var(--content-bg-color);
    position: relative;
    .image {
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      position: relative;
    }
    .content {
      padding: 0.4rem 0.4rem;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      .item {
        font-size: 0.9rem;
        height: 25%;
        line-height: 2rem;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        font-weight: bold;
        text-overflow:ellipsis; 
        overflow:hidden; 
        white-space: nowrap; 
      }
    }
  }
  .charts {
    height: 15rem;
    position: relative;
  }
  .cell {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    height: 3rem;
    align-items: center;
    color: var(--font-color);
    .right {
      color: var(--font-desc-color);
    }
  }
  .carousel {
    height: 15rem;
    padding: 0.75rem 0;
    .buttonRow {
      padding: 0 $button-padding;
      .buttonCol {
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 $button-padding;
        .button {
          width: 100%;
          height: 2.5rem;
          border-radius: 0.5rem;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #02AEFF;
          color: #02AEFF;
          &.active {
            color: #FFFFFF;
            background-color: #02AEFF;
          }
        }
      }
    }
  }
  .dots {
    li {
      width: $dots-width;
      height: $dots-width;
      position: relative;
      margin: 0 0.2rem;
      button {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 100%;
      }
    }
    :global {
      .slick-active {
        width: $dots-width !important;
        border: none;
        button {
          background: #02AEFF !important;
        }
      }
    }
  }

  .closeWraper {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
    position: relative;
    .closeButton {
      width: 100%;
      height: 2.5rem;
      font-size: 1rem;
    }
  }
}

.row {
  // background-color: #FFF;
  background-color: var(--content-bg-color);
}
.space {
  margin-top: $padding-height;
}
