.container {
  // background-color: red;
  // background-color: var(--content-bg-color);
  
  :global {
    .ant-tabs {
      color: var(--font-color);
      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-around;
        background-color: var(--content-bg-color);
        .ant-tabs-tab {
          display: flex;
          justify-content: center;
          font-size: 1rem;
        }
      }
      .ant-tabs-content-holder {
        background-color: var(--content-bg-color);
      }
      .ant-list-bordered {
        border: none;
      }
      .ant-list-header {
        color: var(--font-color);
        border-color: var(--border-color);
        font-size: 1rem;
      }
      .ant-list-item {
        border-color: var(--border-color);
      }
      .ant-list-item-meta-title {
        color: var(--font-color);
        font-size: 1rem;
      }
      .ant-list-item-meta-description {
        color: var(--font-desc-color);
        font-size: 0.7rem;
      }
      // .ant-switch {
      //   transform: scale();
      // }
    }
    
  }

  .header {
    display: flex;
    align-items: center;
    .icon {
      display: inline-block;
      width: 0.2rem;
      background: rgba(26, 144, 254, 1);
      height: 1rem;
      margin-right: 0.2rem;
    }
  }
}